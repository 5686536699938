import React from 'react';

const ONAbout = () => {
	return (
		<div>
			<section id='about' className='solutions flex-columns'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/KEGunn.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h4>À propos de nous</h4>

							<p>
								Après avoir fait carrière dans la haute finance à Toronto et
								Montréal pendant plusieurs années, Kenneth Edward Gunn à fondé
								en 2011 Marchands des Amériques Inc., une compagnie qui aura
								comme vocation de mettre à la disposition des consommateurs des
								vins et spiritueux qui offrent un rapport qualité prix
								exceptionnel. Aujourd'hui, après dix ans, Marchands des
								Amériques a des opérations de l'Atlantique au Pacifique et
								travaille dans pratiquement tous les types de canaux de
								distribution qui sont à notre disposition.
							</p>

							<ul className='listings'>
								<li>
									Les différents monopoles (SAQ, LCBO, BCLDB, NSLC, ANBL,
									PEILIQUOR, NLLIQUOR, MBLL, SLGA)
								</li>
								<li>
									Importations privées pour ventes directes aux restaurants,
									hotels et particuliers
								</li>
								<li>
									Ventes dans les boutiques privées (dans les provinces où cela
									existe)
								</li>
							</ul>
							<p>Levons nos verres aux 10 prochaines années!</p>
							<div className='about-sig'>
								<img
									src={`${process.env.PUBLIC_URL}/img/signature.png`}
									alt=''
								/>
							</div>
							<p>Kenneth Edward Gunn B. Eng MBA</p>
							<p className='about-sig'>Fondateur, Président et CEO</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONAbout;
