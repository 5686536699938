const CatalogSection = (props) => {
	return (
		<div className='catalogSection'>
			<img
				src={`${process.env.PUBLIC_URL}/img/flags/${props.flag}150.png`}
				alt=''
			/>
			<div>{props.section}</div>
		</div>
	);
};

export default CatalogSection;
