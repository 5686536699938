import React from 'react';

const ONWhere = () => {
	return (
		<section className='icons bg-light'>
			<h2 className='flex-items'>WHERE TO FIND OUR PRODUCTS</h2>
			<div className='flex-items'>
				<div className='icon-card'>
					<a href='#products'>
						<img src={`${process.env.PUBLIC_URL}/img/wine.png`} alt='bottle' />
						<div>
							<h3>By the Bottle</h3>
							<p>Retail sales - Wines and spirits found at the LCBO</p>
						</div>
					</a>
				</div>
				<div className='icon-card'>
					<a href='#bythecase'>
						<img
							src={`${process.env.PUBLIC_URL}/img/trolley.png`}
							alt='case of wine'
						/>
						<div>
							<h3>By the Case</h3>
							<p>LCBO Consignment and Destination Collection</p>
						</div>
					</a>
				</div>

				<div className='icon-card'>
					<a href='#restaurants'>
						<img
							src={`${process.env.PUBLIC_URL}/img/restaurant.png`}
							alt='restaurant'
						/>
						<div>
							<h3>At the restaurant</h3>
							<p>Our wines at our restaurant partners</p>
						</div>
					</a>
				</div>
			</div>
		</section>
	);
};

export default ONWhere;
