import React from 'react';

const ONConsignment = () => {
	return (
		<div>
			<section id='bythecase' className='solutions flex-columns flex-reverse'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/caisse.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-secondary'>
							<h4>Order wines by the case</h4>
							<h2>
								We offer a range of LCBO consignment wines tailored to our
								restaurant clientelle.
							</h2>
							<h1>FOR LICENSEE PRICING PLEASE CONTACT US</h1>
							<p></p>
							<a href='#catalog' className='btn btn-outline'>
								<i className='fas fa-chevron'></i>
								See Product List
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONConsignment;
