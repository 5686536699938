import React from 'react';
import Mailto from '../../resources/Mailto';

const Contact = () => {
	return (
		<div id='contact' className='contact bg-primary'>
			<h1 className='p-2'>CONTACTEZ-NOUS</h1>
			<div>Vous avez des questions? Vous voulez commander?</div>
			<div>
				Quelqu'un de l'administration ou de notre équipe de vente se feront un
				plaisir de vous aidez!
			</div>
			<div>Envoyez-nous un courriel</div>
			<div className='bg-light'>
				{' '}
				<Mailto />{' '}
			</div>
		</div>
	);
};

export default Contact;
