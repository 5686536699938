const CatalogItem = (props) => {
	const {
		product_SN,
		region_code,
		product_full_name,
		product_appellation,
		cepages,
		bottles,
		ont_price,
		ont_licensee,
		qc_price,
	} = props.SelectedItem;
	var price = 0;
	var fullPrice = 0;
	if (props.Prov === 'ont') {
		// price = ont_licensee;
		price = ont_price;
		fullPrice = price * 1.13 + 0.2;
	} else if (props.Prov === 'qc') {
		price = qc_price;
		fullPrice = price * 1.14975;
	}
	const product_SN_Short = product_SN.slice(0, 13);

	return (
		<div>
			<div className='catalog-item'>
				<img
					className='cie'
					src={`${process.env.PUBLIC_URL}/img/bottles/${region_code}/${product_SN_Short}xx_bsh300.png`}
					alt=''
				/>

				<div className='cie'>{product_full_name}</div>
				<div className='cie'>{bottles}</div>
				<div className='cie'>${price.toFixed(2)}</div>
				<div className='cie'>{cepages}</div>
				<div className='cie'>${fullPrice.toFixed(2)}</div>
				<div>
					{props.Status === 'oos' ? (
						<div className='overlay'>AVAILABLE SOON</div>
					) : (
						<div></div>
					)}
					{props.Status === 'db' ? (
						<div className='overlay'>Disponible bientôt</div>
					) : (
						<div></div>
					)}
					{props.Status === 'fdl' ? (
						<div className='overlay'>Fin de lôt</div>
					) : (
						<div></div>
					)}
					{props.Status === 'dc' ? (
						<div className='overlay'>Dernière chance</div>
					) : (
						<div></div>
					)}
					{props.Status === 'reb3' ? (
						<div className='overlay'>$3 Rebate Included</div>
					) : (
						<div></div>
					)}
					{props.Status === 'aso' ? (
						<div className='overlay'>ALMOST SOLD OUT</div>
					) : (
						<div></div>
					)}
					{props.Status === 'new' ? (
						<div className='overlay'>NEW</div>
					) : (
						<div></div>
					)}
					{props.Status === 'nou' ? (
						<div className='overlay'>Nouveauté</div>
					) : (
						<div></div>
					)}
					{props.Status === 'ba' ? (
						<div className='overlay'>BY ALLOCATION</div>
					) : (
						<div></div>
					)}
					{props.Status === 'soldout' ? (
						<div className='overlay'>SOLD OUT</div>
					) : (
						<div></div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CatalogItem;
