import React from 'react';

const ONWhere = () => {
	return (
		<section className='icons bg-light'>
			<h2 className='flex-items'>OÙ TROUVER NOS PRODUITS</h2>
			<div className='flex-items'>
				<div className='icon-card'>
					<a href='#products'>
						<img src={`${process.env.PUBLIC_URL}/img/wine.png`} alt='bottle' />
						<div>
							<h3>À LA BOUTEILLE</h3>
							<p>Ventes au détail - Produits à la SAQ</p>
						</div>
					</a>
				</div>
				<div className='icon-card'>
					<a href='#bythecase'>
						<img
							src={`${process.env.PUBLIC_URL}/img/trolley.png`}
							alt='case of wine'
						/>
						<div>
							<h3>À LA CAISSE</h3>
							<p>Importations privées SAQ</p>
						</div>
					</a>
				</div>

				<div className='icon-card'>
					<a href='#restaurants'>
						<img
							src={`${process.env.PUBLIC_URL}/img/restaurant.png`}
							alt='restaurant'
						/>
						<div>
							<h3>AU RESTAURANT</h3>
							<p>Nos partenaires restaurateurs</p>
						</div>
					</a>
				</div>
			</div>
		</section>
	);
};

export default ONWhere;
