import React from 'react';

const ONRestaurants = () => {
	return (
		<div>
			<section
				id='restaurants'
				className='solutions flex-columns  flex-reverse'
			>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/restaurant-wine.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h4>Retrouvez nos vins dans les restaurants du Québec...</h4>
							<h2>
								Notre sélection de vin en importations privées est disponible
								dans des restaurants à travers la province.
							</h2>
							{/* <p>
								Lorem, ipsum dolor sit amet consectetur adipisicing elit.
								Incidunt repudiandae explicabo aut, pariatur aspernatur
								assumenda, illum qui magni exercitationem consequatur fuga amet
								numquam. Aut ipsum dolorum tempore, animi sequi deserunt.
							</p>
							<a href='#' className='btn btn-outline'>
								<i className='fas fa-chevron'></i>
								Read more
							</a> */}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONRestaurants;
