import Mailto from 'react-protected-mailto';

export default () => (
	<div className>
		<Mailto
			email='info@marchandsdesameriques.com'
			headers={{ subject: 'Inquiry from the website' }}
		/>
	</div>
);
