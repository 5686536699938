import '../../layout/layout.css';
import QCHeader from './QCHeader';
import QCWhere from './QCWhere';
import QCReleases from './QCReleases';
import QCPartners from './QCPartners';
import QCRetail from './QCRetail';
import QCConsignment from './QCConsignment';
import QCCatalog from './QCCatalog';
import QCRestaurants from './QCRestaurants';
import QCAbout from './QCAbout';
import './quebec.css';
import QCContact from './QCContact';

const Quebec = () => {
	return (
		<div>
			{/* <QCHeader /> */}
			<main>
				<QCWhere />
				<QCReleases />
				<QCPartners />
				<QCRetail />
				<QCConsignment />
				<QCCatalog />
				<QCRestaurants />
				<QCAbout />
				<QCContact />
			</main>
			<div className='footer'>
				<div className='copyright'>
					Copyright ©2023 Marchands des Amériques Inc.
				</div>
			</div>
		</div>
	);
};

export default Quebec;
