import React from 'react';

const ONConsignment = () => {
	return (
		<div>
			<section id='bythecase' className='solutions flex-columns flex-reverse'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/caisse.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-secondary'>
							<h4>Vins en importations privées - disponibles à la caisse</h4>
							<h2>
								Nous offrons une gamme de produits vendus à la caisse via le
								service des importations privées de la SAQ. Vous trouvez le
								catalogue ci-dessous.
							</h2>
							<p></p>
							<a href='#catalog' className='btn btn-outline'>
								<i className='fas fa-chevron'></i>
								Voir la liste de produits
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONConsignment;
