import React from 'react';
import Mailto from '../../resources/Mailto';

const Contact = () => {
	return (
		<div id='contact' className='contact bg-primary'>
			<h1 className='p-2'>CONTACT US</h1>
			<div>Questions, requests, or if you would like to place an order...</div>
			<div>
				Someone from our headoffice or sales teams will be happy to help!
			</div>
			<div>Send us an email</div>
			<div className='bg-light'>
				{' '}
				<Mailto />{' '}
			</div>
		</div>
	);
};

export default Contact;
