import '../../layout/layout.css';
import ONHeader from './ONHeader';
import ONWhere from './ONWhere';
import ONReleases from './ONReleases';
import ONPartners from './ONPartners';
import ONRetail from './ONRetail';
import ONConsignment from './ONConsignment';
import ONCatalog from './ONCatalog';
import ONRestaurants from './ONRestaurants';
import ONAbout from './ONAbout';
import './ontario.css';
import Contact from './Contact';
import ONMixedCase from './ONMixedCase';

const Ontario = () => {
	return (
		<div>
			{/* <ONHeader /> */}
			<main>
				{/* <ONMixedCase /> */}
				<ONWhere />
				{/* <ONReleases /> */}
				<ONPartners />
				{/* <ONRetail /> */}
				<ONConsignment />
				<ONCatalog />
				<ONRestaurants />
				<ONAbout />
				<Contact />
			</main>
			{/* <div className='footer'>
				<div className='copyright'>
					Copyright ©2021 Marchands des Amériques Inc.
				</div>
			</div> */}
		</div>
	);
};

export default Ontario;
