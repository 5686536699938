import { Route, Switch } from 'react-router-dom';

import './App.css';
import Landing from './layout/Landing';
import Quebec from './provinces/quebec/Quebec';
import Ontario from './provinces/ontario/Ontario';

function App() {
	return (
		<div className='App'>
			<Switch>
				<Route path='/' exact>
					<Landing />
				</Route>
				<Route path='/quebec'>
					<Quebec />
				</Route>
				<Route path='/ontario'>
					<Ontario />
				</Route>
			</Switch>
		</div>
	);
}

export default App;
