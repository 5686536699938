import React from 'react';

const ONPartners = () => {
	return (
		<div>
			<section id='partners' className='solutions flex-columns'>
				<h2 className='flex-items'>Nos partenaires producteurs</h2>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/chateau-larose-trintaudon.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h1>Vignobles de Larose - Médoc Bordeaux</h1>
							<h2>
								Aujourd'hui, Château Larose Trintaudon est le Cru Bourgeois le
								plus vendu au pays!
							</h2>
							<p>
								Partenaire clés de Marchands des Amériques depuis plus de 10
								ans, les Vignobles de Larose sont propriétaires de quatre
								Châteaux iconiques du Médoc. Trois sont en Cru Bourgeois Haut
								Médoc; Château Larose Trintaudon, Château Larose Perganson,
								Château Arnauld et la dernière aquisition, Tour de Pez est un
								Cru Bourgeois Saint-Estèphe.
							</p>
							<a
								href='https://www.vignoblesdelarose.com/'
								target='_blank'
								rel='noreferrer'
								className='btn btn-outline'
							>
								<i className='fas fa-chevron'></i>
								Visitez leur site web
							</a>
						</div>
					</div>
				</div>
			</section>
			<section id='queylus' className='solutions flex-columns flex-reverse'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/queylus.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-secondary'>
							<h1>Domaine Queylus - Péninsule du Niagara</h1>
							<h2></h2>
							<p>
								Le Domaine Queylus est situé au sommet de l’escarpement du
								Niagara, dans la célèbre région vinicole de l’Ontario. ​​Guidé
								par la vision du président fondateur Gilles Chevalier et un
								groupe d'investisseurs enthousiastes du Québec, le Domaine
								Queylus élabore son premier millésime en 2010. Le domaine est
								reconnu pour ses vins de qualité supérieure à climat froid à
								partir de Pinot noir, Chardonnay, Cabernet franc et Merlot. Le
								terroir unique combiné avec l'expérience et le dynamisme de la
								vigneronne en chef, Kelly Mason, résulte en des vins d'une
								élégance exceptionelle.
							</p>
							<a
								href='https://www.queylus.com/'
								target='_blank'
								rel='noreferrer'
								className='btn btn-outline'
							>
								<i className='fas fa-chevron'></i>
								Visitez leur site web
							</a>
						</div>
					</div>
				</div>
			</section>
			<section id='partner-logos'>
				<header className='section-header'>
					<h2>
						Nos partenaires producteurs de vins et spiritueux sont de partout
						sur la planète
					</h2>
					<p></p>
				</header>
				<div className='partner-logos'>
					<div className='partner-logos-box partner-logos-span2'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Larose.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box partner-logos-span2'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Queylus.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Torre.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Tifayne.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Tramier.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/CFGV.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Anecoop.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Trijol.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Ferraud.png`}
							alt='logo'
						/>
					</div>

					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Slavyantsi.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Vinotage.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Feudo.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Marchesi.png`}
							alt='logo'
						/>
					</div>

					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Lavau.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Aspasie.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Richemer.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/PGVF.png`}
							alt='logo'
						/>
					</div>

					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Stonecap.png`}
							alt='logo'
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONPartners;
