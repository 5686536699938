import React from 'react';
import QCProducts from './QCProducts';

const QCRetail = () => {
	return (
		<div>
			<section id='products' className='solutions flex-columns'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img src={`${process.env.PUBLIC_URL}/img/SAQ.jpg`} alt='barrel' />
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h4>Ventes au détail</h4>

							<p>
								Vous trouverez plusieurs de nos produits dans le réseau de la
								SAQ.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className='icons bg-light'>
				<h2 className='flex-items'>DISPONIBLE EN BOUTIQUE OU/ET SUR SAQ.COM</h2>
				<div className='flex-cards'>
					{QCProducts.map((QCP) => (
						<div className='new-card'>
							<div>
								<a
									href={`https://www.saq.com/fr/${QCP.prodnum}`}
									target='_blank'
									rel='noreferrer'
								>
									<img
										src={`${process.env.PUBLIC_URL}/img/bottles/${
											QCP.region
										}/${QCP.product_SN.slice(0, 13)}xx_bsh300.png`}
										alt='bottle'
										rel='noreferrer'
									/>

									<div>
										<h3>{QCP.name}</h3>
										<h3>{QCP.description}</h3>
										<p>
											<span className='new-card-price'>
												{QCP.price.toFixed(2).toString().replace('.', ',')}$
											</span>{' '}
											{QCP.release}
										</p>
									</div>
								</a>
							</div>
						</div>
					))}
				</div>
			</section>
		</div>
	);
};

export default QCRetail;
