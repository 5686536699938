const ONProducts = [
	{
		product_SN: 'vdlaro_cltrin14',
		region: 'bordea',
		name: 'Château Larose Trintaudon',
		description: 'Cru Bourgeois Supérieur Haut Médoc 2018',
		price: 26.3,
		release: 'INCONTOURNABLE',
		prodnum: 11835388,
	},
	{
		product_SN: 'queylu_pinotr19',
		region: 'canada',
		name: 'Domaine Queylus Pinot Noir ',
		description: 'Tradition 2020',
		price: 34.5,
		release: '',
		prodnum: 13276137,
	},
	{
		product_SN: 'passio_lalcht15',
		region: 'bordea',
		name: 'Lalande de Château Tifayne',
		description: 'Francs Côtes de Bordeaux 2015',
		price: 19.75,
		release: '',
		prodnum: 13313292,
	},
	{
		product_SN: 'vdlaro_carnau12',
		region: 'bordea',
		name: 'Château Arnauld',
		description: 'Cru Bourgeois Haut Médoc 2015',
		price: 58,
		release: '',
		prodnum: 13987772,
	},
	{
		product_SN: 'queylu_pingre16',
		region: 'canada',
		name: 'Domaine Queylus Pinot Noir ',
		description: 'Grande Réserve 2016',
		price: 70.75,
		release: '',
		prodnum: 14700278,
	},
	{
		product_SN: 'vdlaro_clperg10',
		region: 'bordea',
		name: 'Château Larose Perganson',
		description: 'Cru Bourgeois Haut Médoc 2015',
		price: 37.5,
		release: 'Disponibles bientôt',
		prodnum: 12116385,
	},
	{
		product_SN: 'vdlaro_cltrin15',
		region: 'bordea',
		name: 'Château Larose Trintaudon 1.5L',
		description: 'Cru Bourgeois Haut Médoc 2015',
		price: 56.75,
		release: '',
		prodnum: 14197422,
	},
	{
		product_SN: 'vdlaro_clperg15',
		region: 'bordea',
		name: 'Château Larose Perganson 1.5L',
		description: 'Cru Bourgeois Haut Médoc 2015',
		price: 72.25,
		release: '',
		prodnum: 14197414,
	},
	{
		product_SN: 'vdlaro_carnau16',
		region: 'bordea',
		name: 'Château Arnauld 1.5L',
		description: 'Cru Bourgeois Haut Médoc 2016',
		price: 111.25,
		release: '',
		prodnum: 14197431,
	},
	{
		product_SN: 'passio_lalcht15',
		region: 'bordea',
		name: 'Lalande de Château Tifayne 1.5L',
		description: 'Francs Côtes de Bordeaux 2014',
		price: 38.5,
		release: '',
		prodnum: 14074246,
	},
	{
		product_SN: 'schust_roterv',
		region: 'europe',
		name: 'Roter Veltliner Schuster 2021',
		description: 'Wagram, Autriche',
		price: 21.25,
		release: '',
		prodnum: 14729281,
	},
];

export default ONProducts;
