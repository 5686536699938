const productData = [
	{
		product_SN: 'rauzan_graneuxx',
		region_code: 'bordea',
		product_full_name: 'Grangeneuve - Bordeaux 2019',
		product_appellation: 'Bordeaux',
		product_colour: 'Red',
		cepages: 'Merlot Cabernet Sauvignon',
		bottles: 12,
		fk_producer: 'rauzan',
		ont_price: 15.9,
		ont_licensee: 14.95,
		qc_price: '',
	},
	{
		product_SN: 'passio_lalcht11',
		region_code: 'bordea',
		product_full_name: 'Lalande de Château Tifayne 2011',
		product_appellation: 'Bordeaux, Francs Côtes de Bordeaux',
		product_colour: 'Red',
		cepages: 'Merlot Cabernet Franc',
		bottles: 12,
		fk_producer: 'passio',
		ont_price: 20.9,
		ont_licensee: 20.9,
		qc_price: 21.79,
	},
	{
		product_SN: 'passio_lalcht14',
		region_code: 'bordea',
		product_full_name: 'Lalande de Château Tifayne 2014',
		product_appellation: 'Bordeaux, Francs Côtes de Bordeaux',
		product_colour: 'Red',
		cepages: 'Francs Côtes de Bordeaux',
		bottles: 12,
		fk_producer: 'passio',
		ont_price: 20.9,
		ont_licensee: 19.95,
		qc_price: 22.9,
	},
	{
		product_SN: 'vdlaro_carnau14',
		region_code: 'bordea',
		product_full_name: 'Château Arnauld 2014',
		product_appellation: 'Cru Bourgeois, Haut Medoc',
		product_colour: 'Red',
		cepages: 'Cru Bourgeois, Haut Medoc',
		bottles: 6,
		fk_producer: 'vdlaro',
		ont_price: 64.95,
		ont_licensee: 59.95,
		qc_price: 58.93,
	},
	{
		product_SN: 'vdlaro_cltrin11',
		region_code: 'bordea',
		product_full_name: 'Château Larose Trintaudon 2011',
		product_appellation: 'Cru Bourgeois, Haut Medoc',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon Merlot',
		bottles: 12,
		fk_producer: 'vdlaro',
		ont_price: 30,
		ont_licensee: 28.25,
		qc_price: 29.5,
	},
	{
		product_SN: 'vdlaro_cltrin14',
		region_code: 'bordea',
		product_full_name: 'Château Larose Trintaudon 2014',
		product_appellation: 'Cru Bourgeois, Haut Medoc',
		product_colour: 'Red',
		cepages: 'Cru Bourgeois, Haut Medoc',
		bottles: 12,
		fk_producer: 'vdlaro',
		ont_price: 30.5,
		ont_licensee: 28.75,
		qc_price: 30.5,
	},
	{
		product_SN: 'vdlaro_clperg08',
		region_code: 'bordea',
		product_full_name: 'Château Larose Perganson 2008',
		product_appellation: 'Cru Bourgeois, Haut Medoc',
		product_colour: 'Red',
		cepages: 'Cru Bourgeois, Haut Medoc',
		bottles: 12,
		fk_producer: 'vdlaro',
		ont_price: 37,
		ont_licensee: 34.95,
		qc_price: 36.5,
	},
	{
		product_SN: 'vdlaro_ctoudp18',
		region_code: 'bordea',
		product_full_name: 'Château Tour de Pez 2018',
		product_appellation: 'Cru Bourgeois, St-Estephe',
		product_colour: 'Red',
		cepages: 'Cru Bourgeois, St-Estèphe',
		bottles: 12,
		fk_producer: 'vdlaro',
		ont_price: '',
		ont_licensee: '',
		qc_price: 41.53,
	},
	{
		product_SN: 'cuveli_pavleo15',
		region_code: 'bordea',
		product_full_name: 'Pavillon de Leoville Poyferre Saint Julien 2015',
		product_appellation: 'Saint Julien',
		product_colour: 'Red',
		cepages: '2nd wine of Ch Leoville Poyferre Grand Cru Classe',
		bottles: 12,
		fk_producer: 'cuveli',
		ont_price: 75.95,
		ont_licensee: 69.95,
		qc_price: '',
	},
	{
		product_SN: 'passio_rocctixx',
		region_code: 'bordea',
		product_full_name: 'Roc de Château Tifayne',
		product_appellation: 'Bordeaux, Francs Côtes de Bordeaux',
		product_colour: 'Red',
		cepages: 'Merlot Cab Franc',
		bottles: 12,
		fk_producer: 'passio',
		ont_price: '',
		ont_licensee: '',
		qc_price: 24.27,
	},
	{
		product_SN: 'bourdi_chjussxx',
		region_code: 'bordea',
		product_full_name: 'Château Jussas',
		product_appellation: 'Bordeaux, Blaye Côtes de Bordeaux',
		product_colour: 'Red',
		cepages: 'Merlot Cabernet Sauvignon Cabernet Franc',
		bottles: 12,
		fk_producer: 'bourdi',
		ont_price: '',
		ont_licensee: '',
		qc_price: 22.31,
	},
	{
		product_SN: 'bourdi_chmberxx',
		region_code: 'bordea',
		product_full_name: 'Château Mayne de Bernard',
		product_appellation: 'Bodeaux, Côtes de Bourg',
		product_colour: 'Red',
		cepages: 'Merlot Cabernet Sauvignon Cabernet Franc',
		bottles: 12,
		fk_producer: 'bourdi',
		ont_price: '',
		ont_licensee: '',
		qc_price: 22.79,
	},
	{
		product_SN: 'tramie_roncblxx',
		region_code: 'bourgo',
		product_full_name: 'Roncier Blanc',
		product_appellation: 'Vin de France',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'tramie',
		ont_price: 15.95,
		ont_licensee: 14.95,
		qc_price: '',
	},
	{
		product_SN: 'tramie_roncrgxx',
		region_code: 'bourgo',
		product_full_name: 'Roncier Rouge',
		product_appellation: 'Vin de France',
		product_colour: 'Red',
		cepages: 'Pinot Noir, Gamay, Syrah',
		bottles: 12,
		fk_producer: 'tramie',
		ont_price: 15.95,
		ont_licensee: 14.95,
		qc_price: '',
	},
	{
		product_SN: 'tramie_secretxx',
		region_code: 'bourgo',
		product_full_name: 'Secret Royal',
		product_appellation: 'Vin de France',
		product_colour: 'Red',
		cepages: 'Pinot Noir, Gamay, Syrah',
		bottles: 12,
		fk_producer: 'tramie',
		ont_price: '',
		ont_licensee: '',
		qc_price: 16.95,
	},
	{
		product_SN: 'dsoupe_chabmt20',
		region_code: 'bourgo',
		product_full_name: 'Chablis 1er Cru Montée de Tonerre 2020',
		product_appellation: 'Bourgogne, Chablis',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'dsoupe',
		ont_price: '',
		ont_licensee: '',
		qc_price: 53.95,
	},
	{
		product_SN: 'dsoupe_chabld18',
		region_code: 'bourgo',
		product_full_name: 'Dom. Soupé Chablis Les Doyennes 2018',
		product_appellation: 'Bourgogne, Chablis',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'dsoupe',
		ont_price: 37.95,
		ont_licensee: 34.95,
		qc_price: 35.95,
	},
	{
		product_SN: 'dsoupe_pechab21',
		region_code: 'bourgo',
		product_full_name: 'Petit Chablis Domaine Soupé 2021',
		product_appellation: 'Bourgogne, Chablis',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'dsoupe',
		ont_price: '',
		ont_licensee: '',
		qc_price: 28.95,
	},
	{
		product_SN: 'ferrau_cavfrbxx',
		region_code: 'bourgo',
		product_full_name: 'La Cave de Fred Cotes de Brouilly 2019',
		product_appellation: 'Beaujolais',
		product_colour: 'Red',
		cepages: 'Gamay',
		bottles: 12,
		fk_producer: 'ferrau',
		ont_price: 24.95,
		ont_licensee: 23.5,
		qc_price: 24.95,
	},
	{
		product_SN: 'ferrau_morgocxx',
		region_code: 'bourgo',
		product_full_name: 'Morgon Les Charmes',
		product_appellation: 'Beaujolais',
		product_colour: 'Red',
		cepages: 'Gamay',
		bottles: 6,
		fk_producer: 'ferrau',
		ont_price: 28.95,
		ont_licensee: 27.25,
		qc_price: '',
	},
	{
		product_SN: 'ferrau_stamenxx',
		region_code: 'bourgo',
		product_full_name: 'Saint Amour Cuvee Encorceleuse',
		product_appellation: 'Beaujolais',
		product_colour: 'Red',
		cepages: 'Gamay',
		bottles: 12,
		fk_producer: 'ferrau',
		ont_price: '',
		ont_licensee: '',
		qc_price: 28.95,
	},
	{
		product_SN: 'ferrau_mavdynxx',
		region_code: 'bourgo',
		product_full_name: 'Moulin A Vent La Dynastie des Ferraud',
		product_appellation: 'Beaujolais',
		product_colour: 'Red',
		cepages: 'Gamay',
		bottles: 6,
		fk_producer: 'ferrau',
		ont_price: '',
		ont_licensee: '',
		qc_price: 35.85,
	},
	{
		product_SN: 'pgvfac_hulial15',
		region_code: 'bourgo',
		product_full_name: 'Hubert Lignier Aligote 2015',
		product_appellation: 'Bourgogne',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 6,
		fk_producer: 'pgvfac',
		ont_price: 31.95,
		ont_licensee: 30.25,
		qc_price: '',
	},
	{
		product_SN: 'pgvfac_bourpn18',
		region_code: 'bourgo',
		product_full_name: 'Alain Corcia Bourgogne Pinot Noir 2018',
		product_appellation: 'Bourgogne',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'pgvfac',
		ont_price: 25.95,
		ont_licensee: 25.95,
		qc_price: '',
	},
	{
		product_SN: 'tramie_bhcdnu19',
		region_code: 'bourgo',
		product_full_name: 'Tramier Bourgogne Haute Cotes de Nuits',
		product_appellation: 'Bourgogne',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 6,
		fk_producer: 'tramie',
		ont_price: 36.95,
		ont_licensee: 34.95,
		qc_price: '',
	},
	{
		product_SN: 'tramie_mermip19',
		region_code: 'bourgo',
		product_full_name: 'Tramier Chateau Mi-Pont Mercurey 2019',
		product_appellation: 'Bourgogne',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'tramie',
		ont_price: 52.0,
		ont_licensee: 48.95,
		qc_price: '',
	},
	{
		product_SN: 'aspasi_chbrutxx',
		region_code: 'bubble',
		product_full_name: 'Champagne Aspasie Réserve Brut',
		product_appellation: 'Champagne',
		product_colour: 'White',
		cepages: 'Chardonnay Pinot Noir Pinot Meunier',
		bottles: 6,
		fk_producer: 'aspasi',
		ont_price: 64,
		ont_licensee: 60.5,
		qc_price: 59.95,
	},
	{
		product_SN: 'presti_pdbrutxx',
		region_code: 'bubble',
		product_full_name: 'Champagne Prestige des Sacres Brut Prestige',
		product_appellation: 'Champagne',
		product_colour: 'White',
		cepages: 'Chardonnay Pinot Noir Pinot Meunier',
		bottles: 6,
		fk_producer: 'presti',
		ont_price: 64,
		ont_licensee: 60.5,
		qc_price: 59.95,
	},
	{
		product_SN: 'cfgvin_bfrancxx',
		region_code: 'bubble',
		product_full_name: 'B François - Methode Traditionnelle',
		product_appellation: 'Loire',
		product_colour: 'White',
		cepages: 'Chenin Blanc Chardonnay Airen',
		bottles: 12,
		fk_producer: 'cfgvin',
		ont_price: 16.9,
		ont_licensee: 15.95,
		qc_price: 18.95,
	},
	{
		product_SN: 'cfgvin_marvilxx',
		region_code: 'bubble',
		product_full_name: 'M de Marville - Methode Charmat',
		product_appellation: 'Loire',
		product_colour: 'White',
		cepages: 'Ugni Blanc',
		bottles: 12,
		fk_producer: 'cfgvin',
		ont_price: 13.9,
		ont_licensee: 12.9,
		qc_price: 14.95,
	},
	{
		product_SN: 'cfgvin_poclbbxx',
		region_code: 'bubble',
		product_full_name: 'Pol Clément Blanc de Blanc Brut',
		product_appellation: 'Loire',
		product_colour: 'White',
		cepages: 'Ugni Blanc',
		bottles: 12,
		fk_producer: 'cfgvin',
		ont_price: '',
		ont_licensee: '',
		qc_price: 13.92,
	},
	{
		product_SN: 'cfgvin_poclbmxx',
		region_code: 'bubble',
		product_full_name: 'Pol Clément Blanc de Blanc Brut Magnum',
		product_appellation: 'Loire',
		product_colour: 'White',
		cepages: 'Ugni Blanc',
		bottles: 6,
		fk_producer: 'cfgvin',
		ont_price: '',
		ont_licensee: '',
		qc_price: 31.85,
	},
	{
		product_SN: 'cfgvin_poclcrxx',
		region_code: 'bubble',
		product_full_name: 'Pol Clément Crémant de Loire',
		product_appellation: 'Loire',
		product_colour: 'White',
		cepages: 'Chenin Blanc Chardonnay Cabernet Franc',
		bottles: 6,
		fk_producer: 'cfgvin',
		ont_price: '',
		ont_licensee: '',
		qc_price: 24.05,
	},
	{
		product_SN: 'queylu_chasig17',
		region_code: 'canada',
		product_full_name: 'Queylus Chardonnay Signature 2017',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: '',
		ont_licensee: '',
		qc_price: 28.92,
	},
	{
		product_SN: 'queylu_chasig18',
		region_code: 'canada',
		product_full_name: 'Queylus Chardonnay Signature 2018',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 22.17,
		ont_licensee: 19.95,
		qc_price: 28.92,
	},
	{
		product_SN: 'queylu_chares18',
		region_code: 'canada',
		product_full_name: 'Queylus Chardonnay Reserve 2018',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: '',
		ont_licensee: '',
		qc_price: 46.75,
	},
	{
		product_SN: 'queylu_merlgr19',
		region_code: 'canada',
		product_full_name: 'Queylus Merlot Grande Reserve 2019',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Merlot',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: '',
		ont_licensee: '',
		qc_price: 57.95,
	},
	{
		product_SN: 'queylu_pnrose21',
		region_code: 'canada',
		product_full_name: 'Queylus Pinot Noir Rose 2021',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Rose',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 21.9,
		ont_licensee: 19.71,
		qc_price: 28.92,
	},
	{
		product_SN: 'queylu_pinres16',
		region_code: 'canada',
		product_full_name: 'Queylus Pinot Noir Reserve 2016',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 39.65,
		ont_licensee: 35.69,
		qc_price: '',
	},
	{
		product_SN: 'queylu_pinsig16',
		region_code: 'canada',
		product_full_name: 'Queylus Pinot Noir Signature 2016',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 25.62,
		ont_licensee: 22.9,
		qc_price: '',
	},
	{
		product_SN: 'queylu_pinsig18',
		region_code: 'canada',
		product_full_name: 'Queylus Pinot Noir Signature 2018',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 26.64,
		ont_licensee: 23.98,
		qc_price: 29.92,
	},
	{
		product_SN: 'queylu_cafsigxx',
		region_code: 'canada',
		product_full_name: 'Queylus Cabernet Franc Signature 2020',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Cabernet Franc',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 25.88,
		ont_licensee: 23.29,
		qc_price: '',
	},
	{
		product_SN: 'queylu_cafrtrxx',
		region_code: 'canada',
		product_full_name: 'Queylus Cabernet Franc Tradition 2020',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Cabernet Franc',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: '',
		ont_licensee: 26.68,
		qc_price: 29.92,
	},
	{
		product_SN: 'queylu_droitexx',
		region_code: 'canada',
		product_full_name: 'Queylus Droite 2018',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Cabernet Franc - Merlot',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 35.18,
		ont_licensee: 29.87,
		qc_price: 38.95,
	},
	{
		product_SN: 'queylu_droite19',
		region_code: 'canada',
		product_full_name: 'Queylus Droite 2019',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Cabernet Franc - Merlot',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: 39.6,
		ont_licensee: 35.64,
		qc_price: '',
	},
	{
		product_SN: 'queylu_cafrrexx',
		region_code: 'canada',
		product_full_name: 'Queylus Cabernet Franc Reserve 2017',
		product_appellation: 'Ontario VQA - Niagara Peninsula',
		product_colour: 'Red',
		cepages: 'Cabernet Franc',
		bottles: 12,
		fk_producer: 'queylu',
		ont_price: '',
		ont_licensee: '',
		qc_price: 38.95,
	},
	{
		product_SN: 'anecoo_iliadaxx',
		region_code: 'espagn',
		product_full_name: 'Iliada',
		product_appellation: 'DO Valencia',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon Tempranillo Syrah',
		bottles: 12,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: '',
	},
	{
		product_SN: 'anecoo_jdjbrrxx',
		region_code: 'espagn',
		product_full_name: 'Juan de Juanes Bronze Tinto',
		product_appellation: 'DO Valencia',
		product_colour: 'Red',
		cepages: 'Syrah Tempranillo Grenache',
		bottles: 12,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: 14.95,
	},
	{
		product_SN: 'anecoo_jdjbrwxx',
		region_code: 'espagn',
		product_full_name: 'Juan de Juanes Bronze Blanc',
		product_appellation: 'DO Valencia',
		product_colour: 'White',
		cepages: 'Macabeo Chardonnay',
		bottles: 12,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: 14.95,
	},
	{
		product_SN: 'anecoo_totacrxx',
		region_code: 'espagn',
		product_full_name: 'Torre Tallada Crianza',
		product_appellation: 'DO Valencia',
		product_colour: 'Red',
		cepages: 'Monastrell',
		bottles: 12,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: 13.95,
	},
	{
		product_SN: 'anecoo_vdpn12xx',
		region_code: 'espagn',
		product_full_name: 'Venta del Puerto N12',
		product_appellation: 'DO Valencia',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon Merlot Tempranillo',
		bottles: 6,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: 27.95,
	},
	{
		product_SN: 'anecoo_vdpn12xx',
		region_code: 'espagn',
		product_full_name: 'Venta del Puerto N18',
		product_appellation: 'DO Valencia',
		product_colour: 'Red',
		cepages: '',
		bottles: 6,
		fk_producer: 'anecoo',
		ont_price: '',
		ont_licensee: '',
		qc_price: '',
	},
	{
		product_SN: 'diezsi_lamota19',
		region_code: 'espagn',
		product_full_name: 'Castillo La Mota Verdejo 2019',
		product_appellation: 'Rueda',
		product_colour: 'White',
		cepages: 'Verdejo',
		bottles: 12,
		fk_producer: 'diezsi',
		ont_price: 13.95,
		ont_licensee: 12.95,
		qc_price: '',
	},
	{
		product_SN: 'diezsi_nekora19',
		region_code: 'espagn',
		product_full_name: 'Nekora Verdejo 2019',
		product_appellation: 'Rueda',
		product_colour: 'White',
		cepages: 'Verdejo',
		bottles: 12,
		fk_producer: 'diezsi',
		ont_price: 15.95,
		ont_licensee: 14.95,
		qc_price: '',
	},
	{
		product_SN: 'diezsi_nekora20',
		region_code: 'espagn',
		product_full_name: 'Nekora Verdejo 2020',
		product_appellation: 'Rueda',
		product_colour: 'White',
		cepages: 'Verdejo',
		bottles: 12,
		fk_producer: 'diezsi',
		ont_price: 18.95,
		ont_licensee: 17.95,
		qc_price: 19.95,
	},
	{
		product_SN: 'melerb_melerrxx',
		region_code: 'espagn',
		product_full_name: 'Meler Reserva',
		product_appellation: 'Somontano',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon Merlot',
		bottles: 12,
		fk_producer: 'melerb',
		ont_price: '',
		ont_licensee: '',
		qc_price: 26.95,
	},
	{
		product_SN: 'diezsi_modiezxx',
		region_code: 'espagn',
		product_full_name: 'Momento Diez 2020',
		product_appellation: 'Rueda',
		product_colour: 'White',
		cepages: 'Verdejo',
		bottles: 12,
		fk_producer: 'diezsi',
		ont_price: 23.95,
		ont_licensee: '',
		qc_price: '',
	},
	{
		product_SN: 'torzam_pecorixx',
		region_code: 'italia',
		product_full_name: 'Pecorino Poggio Salaia',
		product_appellation: 'Abbruzzo',
		product_colour: 'White',
		cepages: 'Pecorino',
		bottles: 12,
		fk_producer: 'torzam',
		ont_price: 22.9,
		ont_licensee: 21.9,
		qc_price: 25.95,
	},
	{
		product_SN: 'torzam_incastxx',
		region_code: 'italia',
		product_full_name: 'Incastro Bianco Madia',
		product_appellation: 'Abbruzzo',
		product_colour: 'White',
		cepages: 'Pecorino',
		bottles: 12,
		fk_producer: 'torzam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 20.95,
	},
	{
		product_SN: 'torzam_madiar17',
		region_code: 'italia',
		product_full_name: "Madia Montepulciano d'Abbruzzo 2017",
		product_appellation: 'Abbruzzo',
		product_colour: 'Red',
		cepages: 'Montepulciano D Abruzzo',
		bottles: 12,
		fk_producer: 'torzam',
		ont_price: 14.82,
		ont_licensee: 13.95,
		qc_price: 19.95,
	},
	{
		product_SN: 'torzam_colmbmxx',
		region_code: 'italia',
		product_full_name: "Colle Maggio Montepulciano d'Abbruzzo",
		product_appellation: 'Abbruzzo',
		product_colour: 'Red',
		cepages: 'Montepulciano D Abruzzo',
		bottles: 12,
		fk_producer: 'torzam',
		ont_price: 24.9,
		ont_licensee: 23.5,
		qc_price: 26.95,
	},
	{
		product_SN: 'liomar_chiasu18',
		region_code: 'italia',
		product_full_name: 'Castello di Monastero Chianti Superiore DOCG',
		product_appellation: 'Tuscany',
		product_colour: 'Red',
		cepages: 'SangioveseCabernet Sauvignon Merlot',
		bottles: 6,
		fk_producer: 'liomar',
		ont_price: '',
		ont_licensee: '',
		qc_price: 25.95,
	},
	{
		product_SN: 'liomar_insiem16',
		region_code: 'italia',
		product_full_name: 'Poggio Alle Sughere Insieme',
		product_appellation: 'Tuscany',
		product_colour: 'Red',
		cepages: 'Sangiovese Shiraz Cabernet Sauvignon ',
		bottles: 6,
		fk_producer: 'liomar',
		ont_price: '',
		ont_licensee: '',
		qc_price: 29.95,
	},
	{
		product_SN: 'qdoarr_sensatxx',
		region_code: 'portug',
		product_full_name: 'Sensato',
		product_appellation: 'Portugal, Alentejo',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon Merlot',
		bottles: 12,
		fk_producer: 'qdoarr',
		ont_price: '',
		ont_licensee: '',
		qc_price: 14.95,
	},
	{
		product_SN: 'criche_henrsb19',
		region_code: 'sudfra',
		product_full_name: 'Henri de Richemer Sauvignon Blanc 2019',
		product_appellation: 'Languedoc',
		product_colour: 'White',
		cepages: 'Sauvignon Blanc',
		bottles: 12,
		fk_producer: 'criche',
		ont_price: 15.9,
		ont_licensee: 14.95,
		qc_price: '',
	},
	{
		product_SN: 'barsal_stmrouxx',
		region_code: 'sudfra',
		product_full_name: 'Domaine Saint Maurice La Rousedo',
		product_appellation: "IGP Pays D'Oc",
		product_colour: 'Rose',
		cepages: 'Grenache Gris Cinsault',
		bottles: 6,
		fk_producer: 'barsal',
		ont_price: 16.9,
		ont_licensee: 16.9,
		qc_price: '',
	},
	{
		product_SN: 'barsal_tacherxx',
		region_code: 'sudfra',
		product_full_name: 'Tacherons Pinot Noir 2017',
		product_appellation: 'Languedoc',
		product_colour: 'Red',
		cepages: 'Pinot Noir',
		bottles: 12,
		fk_producer: 'barsal',
		ont_price: 15.9,
		ont_licensee: 15.9,
		qc_price: '',
	},
	{
		product_SN: 'lavauf_carmig18',
		region_code: 'sudfra',
		product_full_name: 'Domaine de Carmignan 2018',
		product_appellation: 'Côtes du Rhône',
		product_colour: 'Red',
		cepages: 'Grenache Syrah Mourvèdre Carignan Cinsault',
		bottles: 12,
		fk_producer: 'lavauf',
		ont_price: 17.9,
		ont_licensee: 16.9,
		qc_price: '',
	},
	{
		product_SN: 'pgvfac_cltoca19',
		region_code: 'sudfra',
		product_full_name: 'Clermont Tonnerre Cairanne',
		product_appellation: 'Côtes du Rhône Village',
		product_colour: 'Red',
		cepages: 'Grenache Syrah Mourvedre',
		bottles: 12,
		fk_producer: 'pgvfac',
		ont_price: 22.9,
		ont_licensee: 21.95,
		qc_price: '',
	},
	{
		product_SN: 'pgvfac_tatgiv18',
		region_code: 'bourgo',
		product_full_name: 'Dom. Tatraux Givry 1er Cru Blanc 2018',
		product_appellation: 'bourgogne',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 6,
		fk_producer: 'pgvfac',
		ont_price: 59.95,
		ont_licensee: 54.95,
		qc_price: '',
	},
	{
		product_SN: 'stonec_sccharxx',
		region_code: 'usamer',
		product_full_name: 'Stone Cap Chardonnay',
		product_appellation: 'Comumbia Valley Washington',
		product_colour: 'White',
		cepages: 'Chardonnay',
		bottles: 12,
		fk_producer: 'stonec',
		ont_price: 19.95,
		ont_licensee: 18.95,
		qc_price: 21.95,
	},
	{
		product_SN: 'stonec_sccabsxx',
		region_code: 'usamer',
		product_full_name: 'Stone Cap Cabernet Sauvignon',
		product_appellation: 'Comumbia Valley Washington',
		product_colour: 'Red',
		cepages: 'Cabernet Sauvignon',
		bottles: 12,
		fk_producer: 'stonec',
		ont_price: 19.95,
		ont_licensee: 18.95,
		qc_price: 21.95,
	},
	{
		product_SN: 'araujo_malbecxx',
		region_code: 'usamer',
		product_full_name: 'Casa Araujo Malbec',
		product_appellation: 'Mendoza',
		product_colour: 'Red',
		cepages: 'Malbec',
		bottles: 12,
		fk_producer: 'araujo',
		ont_price: '',
		ont_licensee: '',
		qc_price: 16.95,
	},
	{
		product_SN: 'borfam_fleufcxx',
		region_code: 'bubble',
		product_full_name: 'Fleur de Francois Crémant de Bordeaux',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Cabernet Franc Semillon',
		bottles: 12,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 21.95,
	},
	{
		product_SN: 'borfam_lvbldnxx',
		region_code: 'bubble',
		product_full_name: 'Louis Vallon Crémant de Brdx Blanc de Noirs',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Merlot Cabernet Franc',
		bottles: 6,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 26.95,
	},
	{
		product_SN: 'borfam_lvbrunxx',
		region_code: 'bubble',
		product_full_name: 'Louis Vallon Crémant de Brdx Brut Nature',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Merlot Cabernet Franc',
		bottles: 6,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 27.95,
	},
	{
		product_SN: 'borfam_bruedm20',
		region_code: 'bordea',
		product_full_name: 'Château Bruni Entre Deux-Mers',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Sauvignon Blanc Semillon',
		bottles: 12,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 16.95,
	},
	{
		product_SN: 'borfam_brubsu20',
		region_code: 'bordea',
		product_full_name: 'Château Bruni Bordeaux Supérieur',
		product_appellation: 'Bordeaux',
		product_colour: 'Rouge',
		cepages: 'Merlot Cabernet Franc',
		bottles: 12,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 19.95,
	},
	{
		product_SN: 'borfam_sauvebxx',
		region_code: 'bordea',
		product_full_name: 'Sauveterre Blanc Bio',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Sauvignon Blanc',
		bottles: 12,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 20.95,
	},
	{
		product_SN: 'borfam_sauverxx',
		region_code: 'bordea',
		product_full_name: 'Sauveterre Rouge Bio',
		product_appellation: 'Bordeaux',
		product_colour: 'Blanc',
		cepages: 'Merlot Cab Franc Cab Sauvignon',
		bottles: 12,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 20.95,
	},
	{
		product_SN: 'borfam_petnatxx',
		region_code: 'bubble',
		product_full_name: "Pet'Nat Rose",
		product_appellation: 'Bordeaux',
		product_colour: 'Rose',
		cepages: 'Malbec',
		bottles: 6,
		fk_producer: 'borfam',
		ont_price: '',
		ont_licensee: '',
		qc_price: 29.95,
	},
	{
		product_SN: 'liomar_brunel15',
		region_code: 'italia',
		product_full_name: 'Brunello di Montalcino 2015',
		product_appellation: 'Brunello Tuscany',
		product_colour: 'Redd',
		cepages: 'Sangiovese',
		bottles: 6,
		fk_producer: 'liomar',
		ont_price: '',
		ont_licensee: '',
		qc_price: 64.95,
	},
	{
		product_SN: 'liomar_brunel10',
		region_code: 'italia',
		product_full_name: 'Brunello di Montalcino 2010',
		product_appellation: 'Brunello Tuscany',
		product_colour: 'Redd',
		cepages: 'Sangiovese',
		bottles: 6,
		fk_producer: 'liomar',
		ont_price: '',
		ont_licensee: '',
		qc_price: 74.95,
	},
];

export default productData;
