import CatalogItem from '../../resources/CatalogItem';
import CatalogTitle from '../../resources/CatalogTitle';
import ProductData from '../../resources/productData';
import '../../resources/catalog.css';
import CatalogSection from '../../resources/CatalogSection';
import CatalogFooter from '../../resources/CatalogFooter';

const ONCatalog = () => {
	const Item = (itemId) => {
		return ProductData.filter(function (obj) {
			return obj.product_SN === itemId;
		})[0];
	};
	const prov = 'qc';

	return (
		<section id='catalog' className='flex-items'>
			<div></div>
			<div className='catalog'>
				<div className='catalog-col'>
					<CatalogTitle title='Catalogue février 2023' />
					<CatalogSection flag='France' section='Bulles' />
					<CatalogItem
						SelectedItem={Item('cfgvin_marvilxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('borfam_fleufcxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('borfam_lvbldnxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('borfam_lvbrunxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('borfam_petnatxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('presti_pdbrutxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogSection
						flag='Canada'
						section='Domaine Queylus Peninsule de Niagara '
					/>
					<CatalogItem
						SelectedItem={Item('queylu_chasig18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_chares18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_pnrose21')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_pinsig18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_merlgr19')}
						Prov={prov}
						Status=''
					/>

					<CatalogSection flag='Argentina' section='Argentine' />
					<CatalogItem
						SelectedItem={Item('araujo_malbecxx')}
						Prov={prov}
						Status=''
					/>

					<CatalogFooter prov={prov} />
				</div>

				<div className='catalog-col'>
					<CatalogSection flag='France' section='Beaujolais et Bourgogne' />

					<CatalogItem
						SelectedItem={Item('dsoupe_pechab21')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('dsoupe_chabld18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('dsoupe_chabmt20')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('tramie_secretxx')}
						Prov={prov}
						Status=''
					/>

					<CatalogSection flag='France' section='Bordeaux' />
					<CatalogItem
						SelectedItem={Item('borfam_bruedm20')}
						Prov={prov}
						Status=''
					/>

					<CatalogItem
						SelectedItem={Item('borfam_sauvebxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('borfam_sauverxx')}
						Prov={prov}
						Status=''
					/>
					{/* <CatalogItem
						SelectedItem={Item('passio_lalcht14')}
						Prov={prov}
						Status='dc'
					/> */}
					<CatalogItem
						SelectedItem={Item('vdlaro_cltrin14')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('vdlaro_clperg08')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('vdlaro_ctoudp18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('vdlaro_carnau14')}
						Prov={prov}
						Status='db'
					/>
					<CatalogSection flag='Italy' section='Italia' />
					<CatalogItem
						SelectedItem={Item('torzam_madiar17')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('torzam_incastxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('torzam_pecorixx')}
						Prov={prov}
						Status=''
					/>
				</div>
			</div>
		</section>
	);
};

export default ONCatalog;

{
	/* <CatalogSection flag='USA' section='USA - Washington' />
					<CatalogItem
						SelectedItem={Item('stonec_sccharxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('stonec_sccabsxx')}
						Prov={prov}
						Status=''
					/> */
}

{
	/* <CatalogSection flag="Portugal" section="Portugal" />
          <CatalogItem
            SelectedItem={Item('qdoarr_sensatxx')}
            Prov={prov}
            Status=""
          /> */
}

{
	/* <CatalogItem
						SelectedItem={Item('torzam_colmbmxx')}
						Prov={prov}
						Status=''
					/> */
}
{
	/* <CatalogItem
						SelectedItem={Item('liomar_chiasu18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('liomar_insiem16')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('liomar_brunel15')}
						Prov={prov}
						Status=''
					/> */
}

{
	/* <CatalogItem SelectedItem={Item('queylu_pinres')} Prov={prov} /> */
}
{
	/* <CatalogItem
						SelectedItem={Item('queylu_cafrtrxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_droitexx')}
						Prov={prov}
						Status=''
					/> */
}

{
	/* <CatalogItem
						SelectedItem={Item('anecoo_jdjbrrxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('anecoo_vdpn12xx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('diezsi_nekora20')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('melerb_melerrxx')}
						Prov={prov}
						Status=''
					/> */
}

{
	/* <CatalogItem
						SelectedItem={Item('anecoo_totacrxx')}
						Prov={prov}
						Status=''
					/> */
}
{
	/* 
					<CatalogItem
						SelectedItem={Item('courta_pchami20')}
						Prov={prov}
						Status=''
					/> */
}
{
	/* <CatalogItem
						SelectedItem={Item('ferrau_cavfrbxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('ferrau_stamenxx')}
						Prov={prov}
						Status=''
					/> */
}
