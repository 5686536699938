import QCProducts from './QCProducts';

const Releases = () => {
	const releases = QCProducts.slice(0, 4);
	return (
		<section className='icons bg-light'>
			<h2 className='flex-items'>NOUVEAUX ARRIVAGES</h2>
			<div className='flex-cards'>
				{releases.map((QCP) => (
					<div className='new-card'>
						<div>
							<a href={QCP.weblink} target='_blank' rel='noreferrer'>
								<img
									src={`${process.env.PUBLIC_URL}/img/bottles/${
										QCP.region
									}/${QCP.product_SN.slice(0, 13)}xx_bsh300.png`}
									alt='bottle'
									rel='noreferrer'
								/>

								<div>
									<h3>{QCP.name}</h3>
									<h3>{QCP.description}</h3>
									<p>
										<span className='new-card-price'>
											{QCP.price.toFixed(2).toString().replace('.', ',')}$
										</span>{' '}
										{QCP.release}
									</p>
								</div>
							</a>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default Releases;
