import React from 'react';
import { Link } from 'react-router-dom';
import './layout.css';
import './landing.css';

const landing = () => {
	return (
		<div>
			<header className='hero'>
				<div className='navbar' id='navbar'>
					<div className='MDALogo'>
						<img
							src={`${process.env.PUBLIC_URL}/img/mdabarrel.png`}
							alt='barrel'
						/>
						<h1>Marchands des Amériques</h1>
					</div>
				</div>
				<div className='content'>
					<p>Vins et Spiriteux</p>
					<p>Wines and Spirits</p>
					<div>
						Veuillez choisir la province qui vous intéresse/Please select the
						province which interests you
					</div>
					<div className='landing-provs'>
						<Link to='/quebec'>
							<div className='select-prov'>
								<img
									src={`${process.env.PUBLIC_URL}/img/flags/Quebec150.png`}
									alt='Drapeau Quebec'
								/>
								<h3>Québec</h3>
							</div>
						</Link>
						<Link to='/ontario'>
							<div className='select-prov'>
								<img
									src={`${process.env.PUBLIC_URL}/img/flags/Ontario150.png`}
									alt='Ontario Flag'
								/>
								<h3>Ontario</h3>
							</div>
						</Link>
					</div>
				</div>
			</header>

			<div className='footer'>
				<div className='copyright'>
					Copyright ©2021 Marchands des Amériques Inc.
				</div>
			</div>
		</div>
	);
};

export default landing;
