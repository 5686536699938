import React from 'react';

const ONAbout = () => {
	return (
		<div>
			<section id='about' className='solutions flex-columns'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/KEGunn.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h4>About us</h4>

							<p>
								Marchands des Amériques Inc. is a Canadian company specializing
								in the import and sale of wines, spirits and other alcoholic
								beverages. Following a career in institutional sales and trading
								on Bay Street in Toronto and Montreal, Kenneth Edward Gunn
								founded Marchands des Amériques in 2011 and today it operates
								across Canada. We work through the variety of channels available
								to us to bring our wonderful products to consumers in each
								market, including:
							</p>

							<ul className='listings'>
								<li>
									Listings with beverage alcohol monopolies (SAQ, LCBO, BCLDB,
									NSLC, ANBL, PEILIQUOR, NLLIQUOR, MBLL, SLGA)
								</li>
								<li>
									Wholesale to restaurants, hotels and private individuals
								</li>
								<li>Listings with private retailers</li>
							</ul>
							<p>
								We look forward to the next 10 years working across 10
								provinces!
							</p>
							<div className='about-sig'>
								<img
									src={`${process.env.PUBLIC_URL}/img/signature.png`}
									alt=''
								/>
							</div>
							<p>Kenneth Edward Gunn B. Eng MBA</p>
							<p className='about-sig'>Founder, President and CEO</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONAbout;
