import CatalogItem from '../../resources/CatalogItem';
import CatalogTitle from '../../resources/CatalogTitle';
import ProductData from '../../resources/productData';
import '../../resources/catalog.css';
import CatalogSection from '../../resources/CatalogSection';
import CatalogFooter from '../../resources/CatalogFooter';

const ONCatalog = () => {
	const Item = (itemId) => {
		return ProductData.filter(function (obj) {
			return obj.product_SN === itemId;
		})[0];
	};
	const prov = 'ont';

	return (
		<section id='catalog' className='flex-items'>
			<div></div>
			<div className='catalog'>
				<div className='catalog-col'>
					<CatalogTitle title='February 2023 Consignment Catalog' />
					<CatalogSection flag='France' section='Bubbles' />
					<CatalogItem
						SelectedItem={Item('cfgvin_marvilxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('cfgvin_bfrancxx')}
						Prov={prov}
						Status=''
					/>

					{/* <CatalogItem
						SelectedItem={Item('aspasi_chbrutxx')}
						Prov={prov}
						Status=''
					/> */}
					<CatalogSection flag='France' section='Best selling by the glass' />
					<CatalogItem
						SelectedItem={Item('criche_henrsb19')}
						Prov={prov}
						Status=''
					/>
					{/* <CatalogItem
						SelectedItem={Item('tramie_roncblxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('tramie_roncrgxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('rauzan_graneuxx')}
						Prov={prov}
						Status=''
					/> */}
					<CatalogSection flag='France' section='Beaujolais & Burgundy' />
					<CatalogItem
						SelectedItem={Item('dsoupe_chabld18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('pgvfac_tatgiv18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('tramie_mermip19')}
						Prov={prov}
						Status=''
					/>

					<CatalogSection flag='France' section='Bordeaux' />
					{/* <CatalogItem
						SelectedItem={Item('passio_lalcht14')}
						Prov={prov}
						Status=''
					/> */}
					<CatalogItem
						SelectedItem={Item('vdlaro_cltrin14')}
						Prov={prov}
						Status='oos'
					/>
					<CatalogItem
						SelectedItem={Item('vdlaro_clperg08')}
						Prov={prov}
						Status='oos'
					/>
					<CatalogItem
						SelectedItem={Item('vdlaro_carnau14')}
						Prov={prov}
						Status=''
					/>

					<CatalogFooter prov={prov} />
				</div>
				<div className='catalog-col'>
					<CatalogSection flag='France' section='Cotes du Rhone' />
					<CatalogItem
						SelectedItem={Item('lavauf_carmig18')}
						Prov={prov}
						Status=''
					/>

					<CatalogSection
						flag='Canada'
						section='Domaine Queylus Niagara Peninsula'
					/>
					<CatalogItem
						SelectedItem={Item('queylu_chasig18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_pnrose21')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_pinsig18')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_cafsigxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('queylu_droite19')}
						Prov={prov}
						Status=''
					/>

					<CatalogSection flag='Italy' section='Italia' />
					<CatalogItem
						SelectedItem={Item('torzam_madiar17')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('torzam_pecorixx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('torzam_colmbmxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogSection flag='USA' section='USA - Washington' />
					<CatalogItem
						SelectedItem={Item('stonec_sccharxx')}
						Prov={prov}
						Status=''
					/>
					<CatalogItem
						SelectedItem={Item('stonec_sccabsxx')}
						Prov={prov}
						Status='aso'
					/>
				</div>
			</div>
		</section>
	);
};

export default ONCatalog;
