import React from 'react';

const ONPartners = () => {
	return (
		<div>
			<section id='partners' className='solutions flex-columns'>
				<h2 className='flex-items'>Our Producing Partners</h2>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/chateau-larose-trintaudon.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-primary'>
							<h1>Vignobles de Larose - Médoc Bordeaux</h1>
							<h2>
								Today, Château Larose Trintaudon is the best selling Cru
								Bourgeois du Médoc in Canada!
							</h2>
							<p>
								Key partner of Marchands des Amériques for over 10 years,
								Vignobles de Larose own 4 iconic Château in the Médoc. Three are
								Cru Bourgeois Haut Médoc; Château Larose Trintaudon, Château
								Larose Perganson, Château Arnauld and the latest aquisition,
								Tour de Pez is Cru Bourgeois Saint-Estèphe.
							</p>
							<a
								href='https://www.vignoblesdelarose.com/en/'
								target='_blank'
								rel='noreferrer'
								className='btn btn-outline'
							>
								<i className='fas fa-chevron'></i>
								Visit website
							</a>
						</div>
					</div>
				</div>
			</section>
			<section id='queylus' className='solutions flex-columns flex-reverse'>
				<div className='row'>
					<div className='column'>
						<div className='column-1'>
							<img
								src={`${process.env.PUBLIC_URL}/img/queylus.jpg`}
								alt='barrel'
							/>
						</div>
					</div>
					<div className='column'>
						<div className='column-2 bg-secondary'>
							<h1>Domaine Queylus - Niagara Peninsula</h1>
							<h2>
								Outstanding old world style Chardonnay, Pinot Noir and Cabernet
								Franc and Merlot
							</h2>
							<p>
								<p>
									​​Guided by the vision of our founding president, Gilles
									Chevalier, and a group of keen investors from Quebec, Domaine
									Queylus began its winemaking story with its first vintage in
									2010. Combining our strong terroir with the dynamic experience
									of Head Winemaker, Kelly Mason, Domaine Queylus is able to
									achieve an exceptional elegance in their classically styled
									wines.
								</p>
							</p>
							<a
								href='https://www.queylus.com/'
								target='_blank'
								rel='noreferrer'
								className='btn btn-outline'
							>
								<i className='fas fa-chevron'></i>
								Visit website
							</a>
						</div>
					</div>
				</div>
			</section>
			<section id='partner-logos'>
				<header className='section-header'>
					<h2>We have wine producing partners from all over the world</h2>
				</header>
				<div className='partner-logos'>
					<div className='partner-logos-box partner-logos-span2'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Larose.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box partner-logos-span2'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Queylus.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Tramier.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/CFGV.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Anecoop.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Trijol.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Ferraud.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Tifayne.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Slavyantsi.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Vinotage.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Feudo.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Marchesi.png`}
							alt='logo'
						/>
					</div>

					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Lavau.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Aspasie.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Rauzan.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Richemer.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/PGVF.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Torre.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Families.png`}
							alt='logo'
						/>
					</div>
					<div className='partner-logos-box'>
						<img
							src={`${process.env.PUBLIC_URL}/img/logos/Stonecap.png`}
							alt='logo'
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ONPartners;
