const CatalogFooter = (props) => {
	let footerText;

	if (props.prov === 'ont') {
		footerText = (
			<div className='catalogFooter'>
				The price in black is before tax, in blue with tax and bottle deposit
			</div>
		);
	} else if (props.prov === 'qc') {
		footerText = (
			<div>
				{/* <p className='catalogFooter'>
					Charles de Grâce charles@marchandsdesameriques.com 418 801-7428{' '}
				</p> */}
				<div className='catalogFooter'>
					Le prix en noir est avant taxes et le prix en bleu est avec taxes
				</div>
			</div>
		);
	}
	return <div>{footerText}</div>;
};

export default CatalogFooter;
