import logo from '../images/LOGO-MDA.png';

const CatalogTitle = (props) => {
	return (
		<div className='two-col'>
			<img src={logo} alt='' className='logo' />
			<div className='catalogTitle'>{props.title}</div>
		</div>
	);
};

export default CatalogTitle;
